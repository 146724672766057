import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import JotformEmbed from 'react-jotform-embed'
import styled from "styled-components"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const StyledH2 = styled.h2`
  font-weight: 600;
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 19px;
  }
`

const InventoryItem = styled.div`
  border: 1px solid #ccc;
  padding: 6px 3px;
  text-align: center;
`;

const InventoryPage = () => {
  const inventoryList = [
    "ABELIA",
    "ACACIA (Multiple varieties available)",
    "ACANTHUS",
    "ACER",
    "AGAPANTHUS (Multiple varieties available)",
    "AGAVE",
    "AGONIS",
    "ALBIZIA",
    "ALNUS",
    "ALOE",
    "ALYOGYNE",
    "ANIGOZANTHOS",
    "ANISODONTEA",
    "APRICOT",
    "ARALIA",
    "ARBUTUS",
    "ARTEMISIA",
    "ASPIDISTRA",
    "AUCUBA",
    "AVOCADO",
    "AZALEA (Multiple varieties available)",
    "BACCHARIS",
    "BAMBOO",
    "BAUHINIA",
    "BERBERIS",
    "BERGENIA",
    "BETULA",
    "BOUGANVILLEA (Multiple varieties available)",
    "BOWER PLANT",
    "BRACHYCHITON",
    "BRUNSFELSIA",
    "BUDDLEIA",
    "BUXUS (Multiple varieties available)",
    "CAESALPINIA",
    "CALLIANDRA",
    "CALLISTEMON",
    "CAMELLIA (Multiple varieties available)",
    "CANNA",
    "CARISSA (Multiple varieties available)",
    "CASSIA",
    "CEANOTHUS (Multiple varieties available)",
    "CEDRUS",
    "CERATONIA",
    "CERCIS",
    "CHILOPSIS",
    "CHIONANTHUS",
    "CHITALPA",
    "CHORISIA",
    "CINNAMOMUM",
    "CISSUS",
    "CISTUS",
    "CITRUS (Multiple varieties available)",
    "CLIVIA",
    "CLYTOSTOMA",
    "COLEONEMA",
    "CONVOLVULUS",
    "COPROSMA",
    "COTONEASTER",
    "CUPANIOPSIS",
    "CUPHEA",
    "CUPRESSUS",
    "CYPERUS",
    "DANIELA",
    "DASYLIRION",
    "DIETES (Multiple varieties available)",
    "DIOSMA",
    "DISTICTIS",
    "DODONAEA",
    "ECHIUM",
    "ELAEAGNUS",
    "ELAEOCARPUS",
    "EQUISETUM",
    "ERIOBOTRYA",
    "ESCALLONIA",
    "EUCALYPTUS",
    "EUGENIA",
    "EUONYMUS",
    "EURYOPS",
    "FATSIA",
    "FEIJOA",
    "FELICIA",
    "FERNS (Multiple varieties available)",
    "FESTUCA",
    "FICUS",
    "FRAXINUS",
    "FREMONTODENDRON",
    "FRUIT TREES",
    "GARDENIA",
    "GAZANIA",
    "GEIJERA",
    "GELSEMIUM",
    "GINKGO",
    "GLEDITSIA",
    "GREVILLEA",
    "GREWIA",
    "HARDENBERGIA",
    "HEDERA",
    "HEMEROCALLIS (Multiple varieties available)",
    "HESPERALOE",
    "HETEROMELES",
    "HEUCHERA",
    "HIBBERTIA",
    "HIBISCUS (Multiple varieties available)",
    "HYDRANGEA",
    "HYMENOSPORUM",
    "HYPERICUM",
    "ILEX",
    "IVY (Multiple varieties available)",
    "JACARANDA",
    "JASMINUM",
    "JUNIPERUS",
    "JUSTICIA",
    "KNIPHOFIA",
    "KOELREUTERIA",
    "LAGERSTROEMIA (Multiple varieties available)",
    "LANTANA (Multiple varieties available)",
    "LAURUS",
    "LAVANDULA (Multiple varieties available)",
    "LAVATERA",
    "LEUCOPHYLLUM",
    "LIGUSTRUM",
    "LIMONIUM",
    "LIQUIDAMBAR (Multiple varieties available)",
    "LIRIODENDRON",
    "LIRIOPE (Multiple varieties available)",
    "LONICERA",
    "LOROPETALUM",
    "MACFADYENA",
    "MAGNOLIA (Multiple varieties available)",
    "MAHONIA",
    "MANDEVILLEA",
    "MAYTENUS",
    "MELALEUCA",
    "MESQUITE",
    "METROSIDEROS",
    "MISCANTHUS",
    "MORUS",
    "MUHLENBERGIA",
    "MYOPORUM (Multiple varieties available)",
    "MYRICA",
    "MYRSINE",
    "MYRTUS",
    "NANDINA (Multiple varieties available)",
    "NERIUM (Multiple varieties available)",
    "OLEA (Multiple varieties available)",
    "OPHIOPOGON",
    "OSMANTHUS",
    "PALMS (Multiple varieties available)",
    "PANDOREA",
    "PARKINSONIA",
    "PARTHENOCISSUS",
    "PELARGONIUM",
    "PENNISETUM",
    "PENSTEMON",
    "PHILODENDRON",
    "PHORMIUM (Multiple varieties available)",
    "PHOTINIA",
    "PHYLLSTACHYS",
    "PINUS (Multiple varieties available)",
    "PISTACIA",
    "PITTOSPORUM",
    "PLATANUS (Multiple varieties available)",
    "PLUMBAGO",
    "PODOCARPUS (Multiple varieties available)",
    "POLYGALA",
    "POPULUS",
    "PROSOPIS",
    "PRUNUS",
    "PUNICA",
    "PYRACANTHA (Multiple varieties available)",
    "PYRUS (Multiple varieties available)",
    "QUERCUS",
    "RED APPLE",
    "RHAPHIOLEPIS (Multiple varieties available)",
    "RHUS",
    "ROBINA",
    "ROSAS",
    "ROSMARINUS (Multiple varieties available)",
    "RUELLIA",
    "SALIX",
    "SALVIA",
    "SCHEFFLERA",
    "SCHINUS",
    "SOLANUM",
    "SOPHORA",
    "STATICE",
    "STIPA",
    "STRELITZIA (Multiple varieties available)",
    "SYZYGIUM",
    "TABEBUIA",
    "TECOMARIA",
    "TERNSTROEMIA",
    "THEVETIA",
    "TIBOUCHINA",
    "TIPUANA",
    "TRACHELOSPERMUM",
    "TRISTANIA",
    "TULBAGHIA",
    "TUPIDANTHUS",
    "ULMUS (Multiple varieties available)",
    "VERBENA",
    "VIBURNUM",
    "VINCA",
    "WESTRINGIA",
    "WISTERIA",
    "XYLOSMA",
    "YUCCA",
    "ZANTEDESCHIA",
    "ZELKOVA"
  ];

  return (
    <Layout>
      <Seo title="Inventory" />

      <div className="bg-white w-full 2xl:max-w-screen-xl mx-auto">
        <div className="w-full mx-auto pb-5 px-6 xl:px-20">
          <div className="relative">
            <StaticImage
              alt="Inventory Hero Background"
              src={
                "../images/backgrounds/inventory.jpg"
              }
              formats={["auto", "webp", "avif"]}
              width={1180}
              className="z-0"
            />
            
            <h1 className="main-title">Inventory</h1>
          </div>
        </div>

        <div className="w-full flex flex-col items-center mx-auto pb-16 px-6 xl:px-20">
          <div className="w-full mx-auto">
            <StyledH2 className="text-site-green mb-3">Brothers Nursery Full Inventory</StyledH2>

            <p className="font-body text-base italic font-bold mb-3">NOTE: Our inventory is subject to change.</p>

            <p className="font-body text-lg text-red-900 italic font-bold mb-3">Call or email for availability. (909) 599-3469</p>

            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mt-8">
              {inventoryList.map((item, index) => (
                <InventoryItem key={index}>
                  <p>{item}</p>
                </InventoryItem>
              ))}
            </div>
          </div>

          <div className="w-full mx-auto border mt-12">
            <JotformEmbed src="https://form.jotform.com/233212775091453" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default InventoryPage
